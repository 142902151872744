.wrapper-class {
  padding: 1rem;
}
.editor-class {
  background-color:rgb(53,53,53);
  padding: 1rem;
  color: white;
}
.toolbar-class {
  background-color: rgb(53,53,53);
  color: black;
}